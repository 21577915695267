import { IRouteObj } from "interface/common";
import React from "react";
import PageSessionListing from "./views/adminPortal/library/PageTutorialSessionListing";

//Login page
export const linkAdminLogin = "/adminLogin";

//base
export const linkBasePath = "/dashboard";

/* 
    -----------------------------------------------------------------------------------------------------------------------
    Links : 
    -----------------------------------------------------------------------------------------------------------------------
*/
export const linkPortalAdminRoute = "/portal/admin";

export const linkPageUserList = "/userList";

// Foundation

export const linkFoundationBasePath = "/foundation";
export const linkPageFoundationTopicListing = linkFoundationBasePath + "/";
export const linkPageFoundationTopic = linkFoundationBasePath + "/create-topic";

//Module
export const linkModuleBasePath = "/module";
export const linkPageModuleListing = linkModuleBasePath + "/";

export const linkModuleDetailsBasePath = "/moduleDetails";
export const linkPageAdminModuleDetails = linkModuleDetailsBasePath + "/";

export const linkModuleTopicBasePath = "/moduleTopic";
export const linkPageModuleTopic = linkModuleTopicBasePath + "/";

export const linkPageLibraryList = "/library";

export const linkPagePayments = "/payments";

export const linkPageTutorials = "/tutorials";

export const linkPageInvites = "/invites";

//Tutorials

export const linkTutorialCreateSession = linkPageTutorials + "/create-session";

//Library

//Tutorial Archive

//Create Session

export const linkPageTutorialList = linkPageLibraryList + "/tutorialList";

export const linkPageTutorialDetails = linkPageLibraryList + "/tutorialDetails";

export const linkLibraryTutorialCreateSession = linkPageTutorialList + "/create-session";

//Courses

export const linkPageCourseList = linkPageLibraryList + "/courseList";

export const linkPageCourseDetails = linkPageLibraryList + "/courseDetails";

export const linkLibraryCourseCreateSession = linkPageCourseList + "/create-session";

//Podcasts

export const linkPagePodcastList = linkPageLibraryList + "/podcastList";

export const linkPagePodcastDetails = linkPagePodcastList + "/podcastDetails";

export const linkLibraryPodcastCreateSession = linkPagePodcastList + "/create-session";

//Articles
export const linkPageArticleList = linkPageLibraryList + "/articleList";

export const linkLibraryCreateArticle = linkPageLibraryList + "/create-article";

//Meditation
export const linkPageMeditationList = linkPageLibraryList + "/meditationList";

export const linkPageMeditationDetails = linkPageLibraryList + "/meditationDetails";

export const linkLibraryMeditationCreateSession = linkPageMeditationList + "/create-session";

//Events

export const linkPageEventList = linkPageLibraryList + "/eventList";

export const linkPageEventDetails = linkPageLibraryList + "/eventDetails";

export const linkLibraryEventCreateSession = linkPageEventList + "/create-session";
//Tutorials

// Organization
export const linkOrganizationBasePath = "/organization";
export const linkPageOrganizationListing = linkOrganizationBasePath + "/";
export const linkPageOrganizationUserList = linkPageOrganizationListing + "users";

// Analytics

export const linkAnalyticsBasePath = "/analytics";

export const linkPageFoundationAverageScore = linkAnalyticsBasePath + "/foundationAverageScore";

export const linkPageFoundationMemberScoreReport =
  linkAnalyticsBasePath + "/foundationMemberScoreReport";

export const linkPageModulesAverageScore = linkAnalyticsBasePath + "/modulesAverageScore";

export const linkPageModulesMemberScoreReport = linkAnalyticsBasePath + "/modulesMemberScoreReport";

//Pricing plans
export const linkPagePricingPlans = "/pricingPlans";

export const linkPagePricingPlanInfo = linkPagePricingPlans + "/planInfo";

export const linkPagePricingPlanDetails = linkPagePricingPlanInfo + "/details";



/* 
    -----------------------------------------------------------------------------------------------------------------------
    LinkNames : 
    -----------------------------------------------------------------------------------------------------------------------
*/
// User
export const linkNamePageUserList = "User List";

//Foundation
export const linkNamePageFoundationListing = "Module List";

// Module
export const linkNamePageModuleListing = "Module List";

export const linkNamePageAdminModuleDetails = "Module Details";

export const linkNameCreateTopic = "Create Topic";

export const linkNameCreateSession = "Create Session";

//Organization
export const linkNameOrganization = " Organization List";
export const linkNameOrganizationUsers = "Organization Users List";

// Invites

export const linkNameInvites = "Invites";

//Payments
export const linkNamePayments = "Payments";

//Analytics
export const linkNameAnalytics = "Analytics";
export const linkNameFoundationMemberScore = "Analytics Foundation Member Score";
export const linkNameFoundationAverageScore = "Analytics Foundation Average Score";
export const linkNameModulesMemberScore = "Analytics Modules Member Score";
export const linkNameModulesAverageScore = "Analytics Modules Average Score";

//Pricing plans
export const linkNamePricingPlans = "Pricing Plans";

export const linkNamePricingPlanInfo = "Pricing Plan Info";

export const linkNamePricingPlanDetails = "Pricing Plan Details";

/* 
    -----------------------------------------------------------------------------------------------------------------------
    Imports : import files from views
    -----------------------------------------------------------------------------------------------------------------------
*/

const PageUserList = React.lazy(() => import("./views/adminPortal/user/PageUserList"));

// Foundation
const PageFoundationTopicListing = React.lazy(
  () => import("./views/adminPortal/foundation/PageTopicListing")
);

const PageFoundationCreateTopic = React.lazy(
  () => import("./views/adminPortal/foundation/PageCreateTopic")
);

// Module
const PageModuleListing = React.lazy(() => import("./views/adminPortal/module/PageModuleListing"));

const PageAdminModuleDetails = React.lazy(
  () => import("./views/adminPortal/module/PageTopicListing")
);

const PageAdminCreateTopic = React.lazy(
  () => import("./views/adminPortal/module/PageCreateTopic.tsx")
);

//Library
const PageLibraryList = React.lazy(() => import("./views/adminPortal/library/PageLibraryList"));
//Library-Tutorial
const PageTutorialList = React.lazy(
  () => import("./views/adminPortal/library/PageTutorialList/PageTutorialList")
);

const PageAdminCreateSession = React.lazy(
  () => import("./views/adminPortal/library/PageTutorialCreateSession/PageTutorialCreateSession")
);

//Library-Courses

const PageCourseList = React.lazy(
  () => import("./views/adminPortal/library/PageCourseListing/PageCourseListing")
);

const PageCourseSessionList = React.lazy(
  () => import("./views/adminPortal/library/PageCourseSessionListing/PageSessionListing")
);

const PageAdminCourseCreateSession = React.lazy(
  () => import("./views/adminPortal/library/PageCourseCreateSession/PageCourseCreateSession")
);

//Library-Podcast

const PagePodcastList = React.lazy(
  () => import("./views/adminPortal/library/PagePodcastListing/PagePodcastListing")
);

const PagePodcastSessionList = React.lazy(
  () => import("./views/adminPortal/library/PagePodcastSessionListing/PagePodcastSessionListing")
);

const PageAdminPodcastCreateSession = React.lazy(
  () => import("./views/adminPortal/library/PagePodcastCreateSession/PagePodcastCreateSession")
);

//Library-Articles
const PageAdminCreateArticle = React.lazy(
  () => import("./views/adminPortal/library/PageCreateArticle/PageCreateArticle")
);

//Library-Meditation

const PageMeditationList = React.lazy(
  () =>
    import("./views/adminPortal/library/PageMeditation/PageMeditationListing/PageMeditationListing")
);

const PageMeditationSessionList = React.lazy(
  () =>
    import(
      "./views/adminPortal/library/PageMeditation/PageMeditationSessionListing/PageMeditationSessionListing"
    )
);

const PageMeditationCreateSession = React.lazy(
  () =>
    import(
      "./views/adminPortal/library/PageMeditation/PageMeditationCreateSession/PageMeditationCreateSession"
    )
);

//Library-Articles

const PageArticleList = React.lazy(
  () => import("./views/adminPortal/library/PageArticleListing/PageArticleListing")
);

//Library-Events

const PageEventList = React.lazy(
  () => import("./views/adminPortal/library/Events/PageEventListing/PageEventListing")
);

const PageEventSessionList = React.lazy(
  () => import("./views/adminPortal/library/Events/PageEventSessionListing/PageEventSessionListing")
);

const PageEventCreateSession = React.lazy(
  () => import("./views/adminPortal/library/Events/PageEventCreateSession/PageEventCreateSession")
);

//Payments
const PagePayments = React.lazy(() => import("./views/adminPortal/payments/PagePayments"));

//Invites
const PageInvites = React.lazy(()=> import("./views/adminPortal/invites/PageInvites"));

//Tutorials

const PageTutorialsSessionList = React.lazy(
  () =>
    import("./views/adminPortal/tutorials/PageTutorialsSessionListing/PageTutorialsSessionListing")
);

const PageAdminTutorialsCreateSession = React.lazy(
  () =>
    import("./views/adminPortal/tutorials/PageTutorialsCreateSession/PageTutorialsCreateSession")
);

//Organization

const PageOrganizationList = React.lazy(
  () => import("./views/adminPortal/Organization/PageOrganizationList")
);

const PageOrganizationUserList = React.lazy(
  () => import("./views/adminPortal/Organization/PageOrganizationUserList")
);

//Analytics

const PageAdminAnalytics = React.lazy(
  () => import("./views/adminPortal/adminAnalytics/PageAdminAnalytics")
);

const PageAdminFoundationMemberScore = React.lazy(
  () => import("./views/adminPortal/adminAnalytics/PageAdminFoundationMemberScore")
);

const PageAdminFoundationAverageScore = React.lazy(
  () => import("./views/adminPortal/adminAnalytics/PageAdminFoundationAverageScore")
);

const PageAdminModulesMemberScore = React.lazy(
  () => import("./views/adminPortal/adminAnalytics/PageAdminModulesMemberScore")
);

const PageAdminModulesAverageScore = React.lazy(
  () => import("./views/adminPortal/adminAnalytics/PageAdminModulesAverageScore")
);

//Pricing Plans
const PageAdminPricingPlans = React.lazy(
  () => import("./views/adminPortal/pricingPlans/PagePricingPlan/PagePricingPlans")
);

const PageAdminPricingPlanInfo = React.lazy(
  () => import("./views/adminPortal/pricingPlans/PagePlanInfo/PagePlanInfo")
);

const PageAdminPricingPlanDetails = React.lazy(
  () => import("./views/adminPortal/pricingPlans/PagePricingPlanDetails/PagePricingPlanDetails")
);
/* 
    -----------------------------------------------------------------------------------------------------------------------
    Routes : All auth routes
    -----------------------------------------------------------------------------------------------------------------------
*/
const portalAdminRoutes: IRouteObj[] = [
  {
    path: linkPageUserList,
    name: linkNamePageUserList,
    component: PageUserList,
  },
  {
    path: linkPageFoundationTopicListing,
    name: linkNamePageFoundationListing,
    component: PageFoundationTopicListing,
  },
  {
    path: linkPageModuleListing,
    name: linkNamePageModuleListing,
    component: PageModuleListing,
  },
  {
    path: linkPageAdminModuleDetails,
    name: linkNamePageAdminModuleDetails,
    component: PageAdminModuleDetails,
  },
  { component: PageFoundationCreateTopic, path: linkPageFoundationTopic },
  {
    path: linkPageModuleTopic,
    name: linkNameCreateTopic,
    component: PageAdminCreateTopic,
  },
  {
    path: linkPageLibraryList,
    component: PageLibraryList,
  },
  {
    path: linkPageTutorialList,
    component: PageTutorialList,
  },
  { path: linkPageCourseList, component: PageCourseList },
  { path: linkPagePodcastList, component: PagePodcastList },
  { path: linkPageArticleList, component: PageArticleList },
  { path: linkPageEventList, component: PageEventList },
  {
    path: linkPageTutorialDetails,
    component: PageSessionListing,
  },
  {
    path: linkPageCourseDetails,
    component: PageCourseSessionList,
  },
  {
    path: linkPagePodcastDetails,
    component: PagePodcastSessionList,
  },

  {
    path: linkPageEventDetails,
    component: PageEventSessionList,
  },

  {
    path: linkLibraryTutorialCreateSession,
    name: linkNameCreateSession,
    component: PageAdminCreateSession,
  },
  {
    path: linkLibraryCourseCreateSession,
    component: PageAdminCourseCreateSession,
  },
  {
    component: PageAdminTutorialsCreateSession,
    path: linkTutorialCreateSession,
  },
  {
    path: linkLibraryPodcastCreateSession,
    component: PageAdminPodcastCreateSession,
  },
  {
    path: linkLibraryEventCreateSession,
    name: linkNameCreateSession,
    component: PageEventCreateSession,
  },
  {
    path: linkLibraryCreateArticle,
    component: PageAdminCreateArticle,
  },
  //library-meditation
  {
    path: linkPageMeditationList,
    component: PageMeditationList,
  },
  {
    path: linkPageMeditationDetails,
    component: PageMeditationSessionList,
  },
  {
    path: linkLibraryMeditationCreateSession,
    name: linkNameCreateSession,
    component: PageMeditationCreateSession,
  },
  {
    component: PageTutorialsSessionList,
    path: linkPageTutorials,
  },
  {
    path: linkPageOrganizationUserList,
    name: linkNameOrganizationUsers,
    component: PageOrganizationUserList,
  },
  {
    path: linkPageOrganizationListing,
    name: linkNameOrganization,
    component: PageOrganizationList,
  },
  //Payments
  {
    path: linkPagePayments,
    name: linkNamePayments,
    component: PagePayments,
  },
  {
    path: linkPageInvites,
    name: linkNameInvites,
    component: PageInvites,
  },

  //Analytics
  {
    path: linkAnalyticsBasePath,
    name: linkNameAnalytics,
    component: PageAdminAnalytics,
  },
  {
    path: linkPageFoundationMemberScoreReport,
    name: linkNameFoundationMemberScore,
    component: PageAdminFoundationMemberScore,
  },
  {
    path: linkPageFoundationAverageScore,
    name: linkNameFoundationAverageScore,
    component: PageAdminFoundationAverageScore,
  },
  {
    path: linkPageModulesMemberScoreReport,
    name: linkNameModulesMemberScore,
    component: PageAdminModulesMemberScore,
  },
  {
    path: linkPageModulesAverageScore,
    name: linkNameModulesAverageScore,
    component: PageAdminModulesAverageScore,
  },
  //Pricing Plans
  {
    path: linkPagePricingPlans,
    name: linkNamePricingPlans,
    component: PageAdminPricingPlans,
  },
  {
    path: linkPagePricingPlanInfo,
    name: linkNamePricingPlanInfo,
    component: PageAdminPricingPlanInfo,
  },
  {
    path: linkPagePricingPlanDetails,
    name: linkNamePricingPlanDetails,
    component: PageAdminPricingPlanDetails,
  },
];

export default portalAdminRoutes;
